@import '../../base/base';
body.dark {

  h1, h2, h3, h4, h5, h6 {
    color: #e0e6ed;
  }
}

/*
    Basic
*/

body.dark {
.accordion {
  .card {
    border: 2px solid #1b2e4b;
    border-radius: 6px;
    margin-bottom: 4px;
    background: #060818;
  }

  .card-header {
    background-color: #1b2e4b;
    color: #f8538d;
    border-radius: 0;
    padding: 0;
    position: relative;
    border-bottom: none;

    section > div {
      padding: 13px 19px;
      cursor: pointer;
      display: block;
      font-size: 14px;
      letter-spacing: 1px;

      &.collapsed {
        color: #888ea8;
      }

      &:not(.collapsed) {
        color: #22c7d5;
        border-bottom: 2px solid #1b2e4b;
        font-weight: 600;
      }

      .icons {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        padding: 9px;

        svg {
          width: 18px;
          transition: .5s;
          transform: rotate(0);
        }
      }

      &[aria-expanded="true"] .icons svg {
        transform: rotate(180deg);
      }
    }
  }

  .card .card-body {
    p {
      color: #888ea8;
      letter-spacing: 1px;
      font-size: 13px;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    ul {
      margin-bottom: 0;

      li {
        font-size: 12px;
        letter-spacing: 1px;

        &:not(:last-child) {
          margin-bottom: 5px;
        }

        a {
          color: #888ea8;
          font-size: 13px;
          font-weight: 600;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  &.no-outer-spacing {
    border: 1px solid $dark;
    border-radius: 6px;

    .card {
      margin-bottom: 0;
      border: none;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }

      &:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid $dark;
      }
    }

    .card-header section > div:not(.collapsed) {
      border-bottom: none;
    }
  }
}
}

/*
    No Outer Spacing
*/

/*
    Accordin with Icons
*/

body.dark {
  .accordion-icons {
    .accordion-icon {
      display: inline-block;
      margin-right: 10px;

      svg {
        color: #888ea8;
        margin-right: 6px;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        fill: rgba(0, 23, 55, 0.08);
      }
    }

    div:not(.collapsed) .accordion-icon svg {
      color: #22c7d5;
      fill: rgba(27, 85, 226, 0.2392156863);
    }
  }
}